import Choices from 'choices.js';

const element = document.querySelectorAll('.js-select-input');
if (element.length > 0) {
    element.forEach(function (node) {
        let choices = new Choices(node, {
            placeholder: true,
            loadingText: '',
            itemSelectText: '',
            allowHTML: true,
        });
    });
}

const rounded_element = document.querySelectorAll('.js-select-rounded');
if (rounded_element.length > 0) {
    rounded_element.forEach(function (node) {
        let rounded_choices = new Choices(node, {
            placeholder: true,
            searchEnabled: false,
            loadingText: '',
            itemSelectText: '',
            allowHTML: false,
            classNames: {
                containerOuter: 'choices -rounded',
            },
        });
    });
}
